.slider-wrapper{
    margin: 32px 0;
}
.main-content{
    min-height: 100vh;
}
.categories{
    .categories-item{
        margin-bottom: 4.8rem!important;

        .title-md{
            margin-bottom: 2.8rem;
        }
    }
}