@import "../../App.scss";
.sidebar{
    position: fixed;
    top: 0;
    left: 0;
    width: 300px;
    height: 100%;
    background-color: $clr-white;
    box-shadow: rgba(0, 0, 0, 0.1) 1.95px 1.95px 7px;
    padding: 2rem;
    transform: translateX(-100%);
    z-index: 1000;
    transition: $transition-ease;

    .cat-title{
        padding-bottom: 1rem;
    }
    .sidebar-hide-btn{
        position: absolute;
        right: 2rem;
        transition: $transition-ease;
        &:hover{
            color: $clr-orange;
        }
    }
    .cat-list{
        overflow-y: scroll;
        height: calc(100vh - 60px);
        li{
            padding: 0.8rem 0;
            margin-right: 1.2rem;
            border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
            .cat-list-link{
                font-size: 14px;
                font-family: $font-family-manrope;
                letter-spacing: 0.3px;
                transition: $transition-ease;

                &:hover{
                    color: $clr-orange;
                    margin-left: 10px;
                }
            }
        }

        &::-webkit-scrollbar{
            width: 5px;

        }
        &::-webkit-scrollbar-track{
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb{
            border-radius: 10px;
            background-color: lighten($clr-light-orange, 5%);
            outline: 1px solid $clr-light-orange;
        }
    }
}

.hide-sidebar{
    transform: translateX(0);
}