@import "../../App.scss";

.navbar{
    padding-top: 12px;
    padding-bottom: 12px;
    .navbar-brand{
        font-size: 2.4rem;
    }
    .brand-and-toggler{
        .sidebar-show-btn{
            margin-right: 12px;
            margin-top: 4px;
            transition: $transition-ease;
            &:hover{
                opacity: 0.9;
            }
        }
    }
    .navbar-collapse{
        .navbar-search{
            padding: 4px;
            margin-left: 32px;
            border-radius: 2px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

            .form-control{
                width: 100%;
                padding: 0 16px;
                color: rgba(0, 0, 0, 0.8);
                caret-color: $clr-orange;
                &::placeholder{
                    font-size: 13.5px;
                    font-family: $font-family-manrope;
                    letter-spacing: 0.5px;
                }
            }
            .search-btn{
                background-color: $clr-orange;
                width: 60px;
                height: 32px;
            }

            @media screen and (max-width: 576px){
                display: none;
            }
        }
        .navbar-nav{
            margin-left: 32px;
            margin-top: 8px;
            .nav-item{
                margin-right: 16px;
                .nav-link{
                    transition: $transition-ease;
                    &:hover{
                        opacity: 0.95;
                    }
                }
            }
            
            @media screen and (max-width: 992px){
                display: none;
            }
        }
    }
    .navbar-cart{
        margin-left: 32px;
        height: 30px;
        border-radius: 50%;
        font-size: 2rem;

        .cart-btn{
            position: relative;
            .cart-items-value{
                position: absolute;
                top: -10px;
                right: -8px;
                background-color: $clr-white;
                font-size: 14px;
                font-weight: 500;
                color: $clr-orange;
                padding: 0 0.5rem;
                width: 22px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &:hover{
                .cart-modal{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}
