.cart-message{
    padding: 2rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 3px;

    .cart-message-icon{
        margin-bottom: 1.7rem;
        img{
            width: 45px;
            height: 45px;
            margin-right: auto;
            margin-left: auto;
        }
    }
    
}