@import "../../App.scss";

.header{
    background: rgb(249,78,48);
    background: linear-gradient(180deg, rgba(249,78,48,1) 0%, rgba(255,100,51,1) 100%);

    .header-cnt-top{
        border-bottom: 1px solid lighten($clr-orange, 10%);
    }

    @media screen and (max-width: 992px){
        .header-cnt-top{
            flex-direction: column;
            .header-cnt-top-l{
                margin-bottom: 4px;
            }
        }
    }
    
}