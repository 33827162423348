@import "../../App.scss";
.product-lists{
    row-gap: 2rem;

    @media screen and (min-width: 576px){
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1.4rem;
    }

    @media screen and (min-width: 768px){
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (min-width: 1200px){
        grid-template-columns: repeat(4, 1fr);
    }

    @media screen and (min-width: 1400px){
        grid-template-columns: repeat(5, 1fr);
    }
}